<template>
  <div class="contact">
    <navbar />
    <div class="hero is-primary is-medium is-bold">
      <div class="hero-body">
        <img v-if="thumbnail !== ''" class="hero-img" :src="`${API_URL}/public/${thumbnail}`" alt="" />
      </div>
      <div class="hero-foot">
        <div class="thumbnail-copy ml-2" v-html="thumbnailCopy" />
      </div>
    </div>
    <div class="container">
      <section class="articles">
        <div class="column is-8 is-offset-2">
          <div class="card article">
            <div class="card-content">
              <div class="media">
                <div class="media-content has-text-centered">
                  <p class="title article-title">Contato</p>
                </div>
              </div>
              <div class="content article-body">
                <div class="form">
                  <error :error="error" />

                  <div class="field mb-5">
                    <label class="label">Nome</label>
                    <div class="control">
                      <input v-model="name" class="input" type="text" placeholder="Seu nome" ref="name">
                    </div>
                  </div>
                </div>
                <div class="columns">
                  <div class="column">
                    <div class="field">
                      <label class="label">Email</label>
                      <div class="control">
                        <input v-model="email" class="input" type="email" placeholder="Seu email" ref="email">
                      </div>
                    </div>
                  </div>
                  <div class="column">
                    <div class="field">
                      <label class="label">Celular</label>
                      <div class="control">
                        <input v-model="phone" class="input" type="text" placeholder="Seu número" ref="phone">
                      </div>
                    </div>
                  </div>
                </div>
                <div class="field mb-5">
                  <label class="label">Mensagem</label>
                  <div class="control">
                    <textarea v-model="message" class="textarea" placeholder="Sua mensagem" ref="message" />
                  </div>
                </div>
                <div class="field is-grouped is-grouped-centered mt-5">
                  <div class="control">
                    <button class="button is-primary" :class="{'is-loading': enviando}" @click="send()">Enviar Mensagem</button>
                  </div>
                </div>
              </div>
              <div class="media">
                <div class="media-content has-text-centered">
                  <a class="rede has-text-primary mr-2 facebook" href="https://www.instagram.com/coopenaza_oficial/"><i class="lni lni-facebook" /></a>
                  <a class="rede has-text-primary instagram" href="https://www.instagram.com/coopenaza_oficial/"><i class="lni lni-instagram" /></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <Footer />
  </div>
</template>

<script>
import { API_URL } from '@/api'
import Navbar from '@/layout/Navbar'
import Footer from '@/layout/Footer'
import Error from '@/components/ui/Error'

export default {
  name: 'Contact',
  components: {
    Navbar,
    Footer,
    Error
  },
  data () {
    return {
      name: '',
      email: '',
      phone: '',
      message: '',
      thumbnailCopy: 'Photo by <a href="https://unsplash.com/@lunarts?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Volodymyr Hryshchenko</a> on <a href="https://unsplash.com/s/photos/contact-us?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>',
      thumbnail: 'contact.jpg',
      enviando: false,
      error: null
    }
  },
  computed: {
    API_URL () {
      return API_URL
    }
  },
  methods: {
    send () {
      this.enviando = true
    }
  }
}
</script>

<style lang="scss" scoped>
.contact {
  .hero {
    position: relative;
    &-body {
      height: 500px;
      .hero-img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
    .hero-foot {
      position: relative;
      z-index: 1;
    }
  }
  .container {
    .articles {
      margin: 5rem 0;
      margin-top: -200px;
      .article {
        .media {
          margin-bottom: 1.5rem;
          .media-content {
            margin-top: 3rem;
          }
        }
        .article-body {
          text-align: justify;
          margin: 0 2rem;
        }
      }
    }
  }
}
</style>
